import { json } from '@remix-run/react'
import { sdk } from '~/data/sdk.server'
import Page from '~/components/cms/page'
import { useUnwrappedLoaderData } from '~/utils/loader-unwrap'
import { enhancePageWithMarkdown } from '~/domain/cms/markdown.server'
import { getSampleResults } from '~/domain/calc/products.server'
import type { MetaFunction } from '@remix-run/node'
import { makeMetaTitle, mergeMeta } from '~/utils/meta'

export const meta: MetaFunction<typeof loader> = mergeMeta(({ data }) => {
  return [
    {
      name: 'og:title',
      content: makeMetaTitle(data?.title),
    },
    { title: makeMetaTitle(data?.title) },
  ]
})

export async function loader() {
  const pageData = await sdk.Page({ slug: '/' })
  if (!pageData.page) throw new Response('Page not found', { status: 404 })
  return json({
    pageContent: enhancePageWithMarkdown(pageData.page.content),
    title: pageData.page.pageTitle,
    sampleResults: await getSampleResults(),
  })
}

export default function Index() {
  const data = useUnwrappedLoaderData<typeof loader>()
  return data.pageContent ? (
    <Page pageContent={data.pageContent} sampleResults={data.sampleResults} />
  ) : null
}
